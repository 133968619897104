import { VersionControllerClient } from '@foxden/version-controller-client';
import { addDays, formatISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import PhoneInput from 'react-phone-input-2';
import { Redirect } from 'react-router-dom';

import { PaymentClient } from '../../../../backend-client/paymentBackend';
import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Input from '../../../../components/Input/Input';
import Loading from '../../../../components/Loading/Loading';
import Modal from '../../../../components/Modal/Modal';
import {
  AdditionalInsured,
  useSendEndorsementEmailLazyQuery,
  useUpdateApplicationAnswersAndQuoteMutation
} from '../../../../generated/graphql';
import { QuoteInfo } from '../../../../generated/rating-quoting-graphql';
import Group from '../../../../images/Group.svg';
import getEnv from '../../../../utils/getEnv';
import { VerificationVariablesInput } from '../../QuoteTypes';

export interface PopupProps {
  closeAction: () => void;
  quoteId: string;
  isEndorsement: boolean;
  isCancellation: boolean;
  applicationId: string;
  country: string;
  quoteInfo?: QuoteInfo;
  additionalInsuredInfoList?: AdditionalInsured[];
  setQuoteId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCustomerAgreementActive: ((isActive: boolean) => void) | undefined;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
  isAuthProviderLoading: boolean;
}

const getTermsUrl = (baseTermsUrl: string, quoteId: string) => {
  return `${baseTermsUrl}/terms/${quoteId}`;
};

export async function getVersionedTermsUrl(
  quoteId: string,
  versionControllerClient: VersionControllerClient
): Promise<string> {
  const version = await versionControllerClient.getVersionFromQuote(quoteId);
  const baseUrl = PaymentClient.getPaymentFrontendURL(
    version.paymentFrontendVersion
  );
  return getTermsUrl(baseUrl, quoteId);
}

const { REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL } = getEnv();

// TODO: vcc can be passed as a prop from Quote -> BuyNowButton -> Popup
const vcc = new VersionControllerClient(
  REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
);

const Popup: React.FC<PopupProps> = (props) => {
  const {
    closeAction,
    quoteId,
    isEndorsement,
    isCancellation,
    applicationId,
    quoteInfo,
    country,
    additionalInsuredInfoList,
    setQuoteId,
    setCustomerAgreementActive,
    setShowPopup,
    isAuthenticated,
    isAuthProviderLoading
  } = props;

  const { allowDatePickerToPickDateUpToSixtyDays } = useFlags();
  if (!quoteInfo) {
    throw new Error('quoteInfo should be defined');
  }

  const {
    companyName: initialCompanyName,
    dbaName: initialDBAName,
    email: initialEmail,
    phoneNumber: initialPhoneNumber,
    coverageStartDate: initialCoverageStartDate,
    quoteExpireDate
  } = quoteInfo;

  const [companyName, setCompanyName] = useState(initialCompanyName);
  const [dbaName, setDBAName] = useState(initialDBAName);
  const [email, setEmail] = useState(initialEmail);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [coverageStartDate, setCoverageStartDate] = useState(
    new Date(initialCoverageStartDate)
  );

  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [startNewApplication, setStartNewApplication] = useState(false);
  const [isVerifyingInputs, setIsVerifyingInputs] = useState(false);
  const [isFetchingVersion, setIsFetchingVersion] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');
  const expireDate = new Date(quoteExpireDate);

  const today = new Date();

  useEffect(() => {
    const updateTermsUrl = async () => {
      if (!termsUrl) {
        setIsFetchingVersion(true);
        const termsUrl = await getVersionedTermsUrl(quoteId, vcc);
        setIsFetchingVersion(false);

        setTermsUrl(termsUrl);
      }
    };

    updateTermsUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sendEndorsementEmail] = useSendEndorsementEmailLazyQuery({
    variables: {
      quoteId
    },
    onCompleted: () => {
      closeAction();
      window.alert('Endorsement email has been sent to customer.');
    },
    onError: (err) => {
      throw Error(err.message);
    }
  });

  const [
    updateApplicationAnswersAndQuoteMutation,
    { loading: isupdateApplicationAnswersAndQuoteLoading }
  ] = useUpdateApplicationAnswersAndQuoteMutation();

  const onChangeDate = (newDate: Date | null) => {
    if (newDate) {
      setCoverageStartDate(newDate);
      // Since both dates are set to the beginning of the day,
      // we don't want to allow user to proceed if they pick the same date as quote expire date.
      if (newDate >= expireDate) {
        setInvalidDate(true);
      } else {
        setInvalidDate(false);
      }
    }
  };

  const onChangeNumber = (newPhoneNumber: string | null) => {
    if (newPhoneNumber) {
      setPhoneNumber(newPhoneNumber);
      if (!newPhoneNumber.match(/^1\d\d\d\d\d\d\d\d\d\d$/)) {
        setInvalidPhoneNumber(true);
      } else {
        setInvalidPhoneNumber(false);
      }
    } else {
      setInvalidPhoneNumber(true);
    }
  };

  const newApplication = () => {
    setStartNewApplication(true);
  };

  if (startNewApplication) {
    return (
      <Redirect
        to={{
          pathname: `/`
        }}
      />
    );
  }

  const verifyInputs = async (input: VerificationVariablesInput) => {
    setIsVerifyingInputs(true);
    setInvalidEmail(false);
    const {
      data: updateApplicationAnswersAndQuoteData
    } = await updateApplicationAnswersAndQuoteMutation({
      variables: {
        applicationId,
        ...input
      }
    });

    if (!updateApplicationAnswersAndQuoteData) {
      throw new Error('updateBuyNowPopupVariablesData should be ready');
    }

    const updateResult =
      updateApplicationAnswersAndQuoteData.updateApplicationAnswersAndQuote;

    if (!updateResult.isUpdateSuccessful) {
      setInvalidEmail(true); // Fail to update means email is invalid
      setIsVerifyingInputs(false);
    } else {
      if (updateResult.newQuoteId) {
        setQuoteId(updateResult.newQuoteId);
      }
      const showCustomerAgreement =
        isAuthenticated && !isEndorsement && !isCancellation;
      if (showCustomerAgreement) {
        if (setCustomerAgreementActive) {
          setCustomerAgreementActive(true);
        }
        setShowPopup(false);
      } else {
        let finalTermUrl = termsUrl;
        if (updateResult.newQuoteId) {
          setIsFetchingVersion(true);
          finalTermUrl = await getVersionedTermsUrl(
            updateResult.newQuoteId,
            vcc
          );
          setIsFetchingVersion(false);
          setTermsUrl(finalTermUrl);
        }

        if (isEndorsement) {
          sendEndorsementEmail();
        } else if (!isEndorsement && !isCancellation) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'FoxdenGoToTerms'
            }
          });
          window.location.href = finalTermUrl;
        }
      }
    }
  };

  const clickChangePolicy = async () => {
    // If additional insured is undefined, then we just send email
    if (!additionalInsuredInfoList || additionalInsuredInfoList?.length <= 0) {
      sendEndorsementEmail();
    } else {
      // If additionalInsured is defined, we need to call updateApplicationAndQuote api to update info
      await verifyInputs({
        newEffectiveDate: formatISO(coverageStartDate, {
          representation: 'date'
        }),
        newEmail: email.trim(),
        newPhoneNumber: phoneNumber,
        newCompanyName: companyName.trim(),
        newDBAName: dbaName?.trim(),
        additionalInsuredInfoList
      });
    }
  };

  if (
    isAuthProviderLoading ||
    isupdateApplicationAnswersAndQuoteLoading ||
    isVerifyingInputs ||
    isFetchingVersion
  ) {
    return (
      <div className="modal">
        <div className="modal-content loading text-mainFont center">
          <Loading />
        </div>
      </div>
    );
  }

  if (isEndorsement) {
    return (
      <Modal closeAction={closeAction} onAction={clickChangePolicy}>
        <div className="text-primary center">
          <p>
            <b>
              At Foxquilt, protecting you and your business is our core mission.
            </b>
          </p>
          <p>For your security, we need you to verify your identity.</p>
          <p>
            Please authenticate yourself by creating an authentication account
            with Auth0 to help verify your identity - that way, we know
            it&apos;s really you!
          </p>
        </div>
      </Modal>
    );
  }

  // TODO: Refactor buttons to either all use <button/> tag or ustom <Button/> React component
  return (
    <div className="fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-50 z-50">
      <div className="flex h-screen justify-center overflow-auto">
        <div className="h-fit-content flex flex-col max-w-xl bg-white border-2 border-white rounded-lg text-left p-4 md:p-8 mx-2 md:mx-0 mt-16 z-10 overflow-scroll">
          <div className="flex justify-center mb-2">
            <img className="h-auto w-auto" src={Group} />
          </div>
          <h2 className="text-2xl font-bold mb-2 text-center">
            At Foxquilt, protecting you and your business is our core mission
          </h2>
          <p className="mb-2">
            Please review and confirm the following information is correct:
          </p>
          <p className={`${companyName ? '' : 'invalid-entry'} font-bold mt-4`}>
            Business Name
          </p>
          <Input
            inputClass={`${companyName ? '' : 'invalid-row'} mb-4`}
            id="name"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e)}
          />
          {!companyName ? (
            <p className="text-sm invalid-entry font-bold mb-1">
              This field is required for verification purposes.
            </p>
          ) : null}
          {country !== 'Canada' ? (
            <>
              <p className="font-bold mt-4">DBA (if applicable)</p>
              <Input
                inputClass="mb-4"
                id="name"
                type="text"
                value={dbaName || ''}
                onChange={(e) => setDBAName(e)}
              />
            </>
          ) : null}
          <p
            className={`${
              email || invalidEmail ? '' : 'invalid-entry'
            } font-bold mt-4`}
          >
            Business Email
          </p>
          <Input
            id="email"
            inputClass={`${email || invalidEmail ? '' : 'invalid-row'} mb-4`}
            type="text"
            value={email}
            onChange={(e) => setEmail(e)}
          />
          {invalidEmail ? (
            <p className="text-sm invalid-entry font-bold mb-1">
              Please enter a valid email.
            </p>
          ) : null}
          {!email ? (
            <p className="text-sm invalid-entry font-bold mb-1">
              This field is required for verification purposes.
            </p>
          ) : null}
          <p
            className={`font-bold mt-4 ${
              invalidPhoneNumber ? 'invalid-entry' : ''
            }`}
          >
            Phone Number
          </p>
          <div id="phone-number">
            <PhoneInput
              value={phoneNumber}
              onlyCountries={['ca', 'us']}
              country={'us'}
              buttonClass={`${
                invalidPhoneNumber ? 'invalid-row' : ''
              } phone-number-button bg-secondary px-2 mt-4 rounded-tl-2xl rounded-bl-2xl`}
              inputClass={`${invalidPhoneNumber ? 'invalid-row' : ''}`}
              containerClass={`input-field-container mb-4`}
              onChange={onChangeNumber}
            />
          </div>
          {invalidPhoneNumber ? (
            <p className="text-sm invalid-entry font-bold mb-1">
              Invalid phone number.
            </p>
          ) : null}
          <p className={`font-bold mt-4 ${invalidDate ? 'invalid-entry' : ''}`}>
            Requested Coverage Start Date
          </p>
          <div id="date">
            <DatePicker
              inputClass={`${invalidDate ? 'invalid-row' : ''} mb-4`}
              onChange={onChangeDate}
              name={'calendar'}
              value={coverageStartDate}
              minDate={today}
              maxDate={
                allowDatePickerToPickDateUpToSixtyDays
                  ? addDays(today, 60)
                  : addDays(today, 30)
              }
              helperText={''}
              readOnly={false}
            />
          </div>
          {invalidDate ? (
            <div className="text-sm invalid-entry font-bold mb-1">
              Note: The quote provided is only valid for 30 days. Please select
              a date prior to {quoteExpireDate} or&nbsp;
              <button className="underline font-bold" onClick={newApplication}>
                start a new application
              </button>
              .
            </div>
          ) : null}
          <p className="my-4">
            Next, please click the Proceed button to verify the contact email
            address to ensure policy documents will be delivered.
          </p>
          <p className="mb-4">
            After which you&apos;ll electronically sign the Terms & Conditions
            using Docusign, pay for your policy via credit card and we&apos;ll
            immediately email you your policy documents.
          </p>
          <Button
            className="bg-tertiary uppercase pointer font-bold px-24 py-3 font-raleway text-lg rounded-lg text-white"
            onClick={() => {
              verifyInputs({
                newEffectiveDate: formatISO(coverageStartDate, {
                  representation: 'date'
                }),
                newEmail: email.trim(),
                newPhoneNumber: phoneNumber,
                newCompanyName: companyName.trim(),
                newDBAName: dbaName?.trim(),
                additionalInsuredInfoList
              });
            }}
            buttonText="Proceed"
            disabled={
              !(!invalidPhoneNumber && !invalidDate && !!companyName && !!email)
            }
          />
          {closeAction ? (
            <button
              className="mt-2 underline text-base font-raleway sm:text-mobileFont"
              onClick={closeAction}
            >
              {'Close'}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Popup;
