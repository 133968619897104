import './BuyNowButton.css';

import { WorkflowVersion } from '@foxden/version-controller-client';
import { EmailOutlined, Warning } from '@material-ui/icons';
import React, { useContext, useState } from 'react';

import { RatingQuotingClient } from '../../../../backend-client/ratingQuotingBackend';
import Spinner from '../../../../components/Spinner';
import {
  AdditionalInsured,
  QuoteInfo
} from '../../../../generated/rating-quoting-graphql';
import Download from '../../../../images/Download.svg';
import getEnv from '../../../../utils/getEnv';
import { Context, ContextValue } from '../../../../utils/store';
import { useFlags } from '../../../../utils/useFlags';
import PaymentMethod from '../PaymentMethod';
import QuoteLinkPopup from '../QuoteLinkPopup';
import Popup from './Popup';

const { REACT_APP_BUY_NOW_TRACKING_URL } = getEnv();
export interface BuyNowButtonProps {
  munichQuoteId?: string;
  amtrustQuoteId?: number;
  applicationId: string;
  showSendQuoteButton: boolean;
  country: string;
  quoteInfo?: QuoteInfo;
  disabled: boolean;
  setQuoteId: React.Dispatch<React.SetStateAction<string | undefined>>;
  additionalInsuredInfoList?: AdditionalInsured[];
  validateAdditionalInsured: () => boolean;
  versionData?: WorkflowVersion;
  quoteNumber: string;
  isCustomerAgreementActive: boolean;
  isAuthenticated: boolean;
  isAuthProviderLoading: boolean;
}

const FOXQUILT_TERMS_OF_USE = 'https://www.foxquilt.com/terms-of-use.html';
const FOXQUILT_PRIVACY_NOTICE = 'https://www.foxquilt.com/privacy-policy.html';

interface BuyNowButtonTrackingData {
  applicationId: string;
  munichQuoteId: string;
}
const buyNowButtonTracking = (
  buyNowButtonTrackingData: BuyNowButtonTrackingData
) => {
  try {
    fetch(REACT_APP_BUY_NOW_TRACKING_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(buyNowButtonTrackingData)
    });
  } catch (e) {
    // don't show error of this api call to client
  }
};

const BuyNowButton: React.FC<BuyNowButtonProps> = (props) => {
  const flags = useFlags();
  const {
    munichQuoteId,
    applicationId,
    country,
    quoteInfo,
    disabled,
    setQuoteId,
    additionalInsuredInfoList,
    validateAdditionalInsured,
    versionData,
    quoteNumber,
    isCustomerAgreementActive,
    isAuthenticated,
    isAuthProviderLoading
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [generateQuotePdfError, setGenerateQuotePdfError] = useState<
    string | undefined
  >(undefined);

  const isEndorsement = quoteInfo?.transactionType === 'Endorsement';
  const isCancellation = quoteInfo?.transactionType === 'Cancellation';
  const { amTrustFullFlow } = flags;
  const closePopup = () => {
    setShowPopup(false);
  };
  const context: ContextValue | null = useContext(Context);
  const setCustomerAgreementActive = context?.setCustomerAgreementActive;

  if (!amTrustFullFlow && !munichQuoteId) {
    throw new Error('do not support invalid munichQuote yet');
  }

  const buttonName = isEndorsement ? 'CHANGE POLICY' : 'BUY NOW';

  const downloadPdf = async () => {
    if (versionData && munichQuoteId) {
      setIsPdfLoading(true);
      const ratingQuotingClient = new RatingQuotingClient(
        versionData.quoteBackendVersion
      );
      await ratingQuotingClient
        .generateQuotePdf(munichQuoteId)
        .then((data) => {
          const pdfFileName = `Foxquilt_Quote_${quoteInfo?.companyName}_${quoteNumber}.pdf`;
          // Decode base64 string to binary string
          const binaryString = atob(data);
          // Create a byte array from the binary string
          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }
          // Convert binary buffer to Blob
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          // Create object URL
          const url = window.URL.createObjectURL(blob);

          if (isCustomerAgreementActive) {
            window.open(url);
          } else {
            // Create a download link
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdfFileName); // File name
            document.body.appendChild(link);
            // Trigger click event to start download
            link.click();
            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        })
        .catch((err) => {
          setGenerateQuotePdfError(err);
        });
      setIsPdfLoading(false);
    }
  };

  const QuotePDF = (
    <button
      className="w-full mt-2 border-2 border-faded rounded-md bg-white hover:bg-background text-quartary font-semibold py-1 px-3 md:px-14 flex justify-center items-center disabled:cursor-wait"
      onClick={downloadPdf}
      disabled={isPdfLoading}
    >
      {isPdfLoading ? (
        <>
          <Spinner className="h-6 w-6 mr-2" />
          Loading...
        </>
      ) : !isCustomerAgreementActive ? (
        <>
          <img className="h-auto w-auto inline mr-2" src={Download} />
          Download Quote
        </>
      ) : (
        'View Quote PDF'
      )}
    </button>
  );

  const clientUI = (
    <>
      <button
        disabled={disabled}
        className="w-full bg-tertiary hover:bg-tertiary-dark border-tertiary-dark rounded-md uppercase pointer font-bold px-14 py-3 mb-2 text-mobileFont md:text-xl text-white confirm-buy"
        onClick={() => {
          // Validate the Additional Insured information
          const validationResult = validateAdditionalInsured();
          if (validationResult) {
            setShowPopup(true);
            if (munichQuoteId) {
              buyNowButtonTracking({ applicationId, munichQuoteId });
            }
          } else {
            const additionalInsuredEle = document.getElementById(
              'additional-insured-container'
            );
            additionalInsuredEle
              ? additionalInsuredEle.scrollIntoView({
                  behavior: 'smooth'
                })
              : null;
          }
        }}
      >
        {buttonName}
      </button>
      <p className="text-center text-xs mt-2 mx-auto">
        By clicking {buttonName}, you agree to Foxquilt&apos;s{' '}
        <a
          className="small-underline text-secondary"
          href={FOXQUILT_TERMS_OF_USE}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          className="small-underline text-secondary"
          href={FOXQUILT_PRIVACY_NOTICE}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
      </p>
      <p className="text-center mt-2 text-xs mb-2">
        Your coverage will not be effective until payment is confirmed
      </p>
      <PaymentMethod />
      <div className="border-gray-300 border-t-2 border-b-2 py-4">
        <button
          className="w-full border-2 border-faded rounded-md bg-white hover:bg-background text-quartary font-semibold py-1 px-3 md:px-14"
          onClick={() => setDisplayModal(true)}
        >
          <EmailOutlined className="mb-1 mr-2" />
          Send Quote
        </button>
        {!isEndorsement ? QuotePDF : null}
      </div>
    </>
  );

  const brokerUI = (
    <>
      <div className="policy-warning flex flex-row items-center">
        <Warning
          style={{ color: '#FFD64E' }}
          fontSize="large"
          className="mr-2"
        />
        <span data-testid="policy-warning">
          Coverage is <b>NOT</b> bound, and a policy will <b>NOT</b> be issued
          until both the application is signed <b>AND</b> payment received.
        </span>
      </div>
      <div className="border-gray-300 border-t-2 border-b-2 py-4">
        {!isEndorsement ? QuotePDF : null}
      </div>
    </>
  );

  if (generateQuotePdfError) {
    throw Error(generateQuotePdfError);
  }

  return (
    <div className="confirm-pay-container lg:max-w-xs text-center sm:mt-0">
      {!isCustomerAgreementActive ? clientUI : brokerUI}
      {showPopup && munichQuoteId ? (
        <Popup
          closeAction={closePopup}
          quoteId={munichQuoteId}
          isEndorsement={isEndorsement}
          isCancellation={isCancellation}
          quoteInfo={quoteInfo}
          applicationId={applicationId}
          country={country}
          additionalInsuredInfoList={additionalInsuredInfoList}
          setQuoteId={setQuoteId}
          setShowPopup={setShowPopup}
          setCustomerAgreementActive={setCustomerAgreementActive}
          isAuthenticated={isAuthenticated}
          isAuthProviderLoading={isAuthProviderLoading}
        />
      ) : null}

      {quoteInfo ? (
        <QuoteLinkPopup
          displayModal={displayModal}
          handleClose={() => setDisplayModal(false)}
          applicationId={applicationId}
          quoteInfo={quoteInfo}
          additionalInsuredInfoList={additionalInsuredInfoList}
          setQuoteId={setQuoteId}
        />
      ) : null}
    </div>
  );
};

export default BuyNowButton;
